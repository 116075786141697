import * as React from 'react'
import { ReservationDateSelection } from './reservation-date-selection'
import { Dates } from 'dates-selection-range'

interface Props {
  onMoveToReservationProcess: () => void
  onShowDatePicker: () => void
  onHideDatePicker: () => void
  setDates: (dates: Dates) => void
  seasons: number[]
  resortName: string
}

export const ReservationDesktopCalendar = ({
  onMoveToReservationProcess,
  onShowDatePicker,
  onHideDatePicker,
  setDates,
  seasons,
  resortName,
}: Props): JSX.Element => (
  <div
    className={`container main-page__reservation-box bg-white d-lg-flex d-none ${resortName ? '' : 'no-localization'}`}
  >
    {resortName && (
      <div className="main-page__reservation-box-el">
        <div className="d-flex flex-column justify-content-start">
          <div className="d-flex">
            <i className="uil uil-map-marker text-gold main-page__icon font-15 lh-1" />{' '}
            <span className="font-12">Lokalizacja</span>
          </div>
          <strong className="d-block font-16">{resortName}</strong>
        </div>
      </div>
    )}
    <div className="date-range-wrapper" onClick={onShowDatePicker}>
      <ReservationDateSelection
        seasons={seasons}
        months={2}
        setDates={setDates}
        onVisibilityChange={onHideDatePicker}
      />
    </div>
    <div className="main-page__reservation-box-el">
      <button className="btn btn-red main-page__button" onClick={onMoveToReservationProcess}>
        Rezerwuj
      </button>
    </div>
  </div>
)
