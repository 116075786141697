export function initBlogPagination(): void {
  const input: HTMLInputElement | null = document.querySelector('#pagination-input')
  const prev = document.querySelector('#pagination-prev')
  const next = document.querySelector('#pagination-next')

  input?.addEventListener('input', (event: Event) => {
    const value = (event.target as HTMLInputElement).value
    if (value.toString().length > 2) {
      input.value = value.slice(0, 2)
    }
  })

  next?.addEventListener('click', () => {
    if (input) {
      const newValue = parseInt(input.value) + 1
      if (newValue <= parseInt(input.max)) {
        input.value = newValue.toString()
      }
    }
  })

  prev?.addEventListener('click', () => {
    if (input) {
      const newValue = parseInt(input.value) - 1
      if (newValue >= parseInt(input.min)) {
        input.value = newValue.toString()
      }
    }
  })
}
