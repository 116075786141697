import Modal from 'bootstrap/js/dist/modal'
import { GTMEvents, SessionStorageKeys } from './models'

const POPUP = 'popup'

const $popup = document.getElementById('popup')
const $popupCloseIcon = document.getElementById('popup-close-icon')

export const initPopups = (): void => {
  if (!$popup) return

  const displayedPopups = window.sessionStorage.getItem(POPUP)?.split(',') ?? []

  const modal = new Modal($popup)

  if ($popup.dataset.popupid && !displayedPopups.includes($popup.dataset.popupid)) {
    modal.show()
    const newValue = [...displayedPopups, String($popup.dataset.popupid)]
    window.sessionStorage.setItem(POPUP, newValue.length > 1 ? newValue.join(',') : newValue.join(''))
  }

  $popup.addEventListener('click', () => {
    window.dataLayer?.push({
      event: GTMEvents.Popup_Click,
      userID: window.sessionStorage.getItem(SessionStorageKeys.userID),
    })
  })

  $popupCloseIcon?.addEventListener('click', (event: MouseEvent) => {
    event.stopPropagation()
    modal.hide()
  })
}
