import { Carousel } from '@fancyapps/ui'

export enum DeviceSize {
  desktop = '(min-width: 1200px)',
  tablet = '(min-width: 758px) and (max-width: 1199px)',
  mobile = '(max-width: 757px)',
}

export function initResponsiveSlidesCarousel(carouselHtmlElement: HTMLElement | null): void {
  const mediaXs = window.matchMedia(DeviceSize.mobile)
  const mediaSm = window.matchMedia(DeviceSize.tablet)

  let carousel: Carousel | null

  const createCarousel = () => {
    if (carousel) carousel.destroy()

    const slidesPerPage = mediaXs.matches ? 1 : mediaSm.matches ? 2 : 4
    const center = mediaXs.matches ? 'center' : 'false'
    carousel = createSpecialOfferCarousel(carouselHtmlElement, { slidesPerPage, center })
  }

  createCarousel()

  window.addEventListener('resize', createCarousel)
}

const createSpecialOfferCarousel = (carouselHtmlElement: HTMLElement | null, { slidesPerPage, center }) => {
  if (!carouselHtmlElement) return null

  return new Carousel(carouselHtmlElement, {
    slidesPerPage,
    center,
    infinite: false,
    touch: false,
  })
}
