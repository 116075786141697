import '@iconscout/unicons/css/line.css'
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/modal'
import 'bootstrap/js/dist/dropdown'

import 'bootstrap/js/dist/carousel'
import '@fancyapps/ui/dist/fancybox.css'

import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import 'dates-selection-range/build/styles.css'
import '@pqina/flip/dist/flip.min.css'

import * as React from 'react'
import * as ReactDOM from 'react-dom'

import './assets/styles/app.scss'
import { attachHamburgerEvent } from './ts/hamburger'
import { attachGoTopBtn } from './ts/go-top-btn'
import { initFilters } from './ts/filter'
import { initExpandText } from './ts/expand-text'
import { initMaps } from './ts/maps'
import { initPriceList } from './ts/pricelist'
import { initGalleries, initVoucherUsageCarousel } from './ts/carousel'
import initRange from './ts/range'
import { Fancybox } from '@fancyapps/ui'
import App from './ts/app'
import { initBlogPagination } from './ts/blog-pagination'
import { initWeatherBox } from './ts/weather'
import { initNewsletter } from './ts/newsletter'
import { initSpecialOfferCarousel } from './ts/special-offer-carousel'
import { fetchAppData } from './ts/app-data'
import { initPopups } from './ts/popups'
import { attachVirtualWalkButtonEvent } from './ts/virtual-walk'
import { initResortMapModals } from './ts/resort-map-modal'
import { initEventPageActions } from './ts/events/event-page-actions'
import { initEcommerceBanner } from './ts/ecommerce-banner'

window.addEventListener('DOMContentLoaded', () => {
  fetchAppData()
  initPopups()

  attachHamburgerEvent()
  attachVirtualWalkButtonEvent()
  attachGoTopBtn()

  initFilters()
  initExpandText()

  initRange()
  initMaps()
  initPriceList()
  initVoucherUsageCarousel()
  initEventPageActions()

  initGalleries()
  initBlogPagination()
  initWeatherBox()
  initEcommerceBanner()
  initNewsletter()
  initSpecialOfferCarousel()
  initResortMapModals()

  Fancybox.bind('[data-fancybox]', {})
})

ReactDOM.render(<App />, document.getElementById('react-app'))
