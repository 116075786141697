import { Carousel } from '@fancyapps/ui'

const $galleries = document.querySelectorAll<HTMLButtonElement>('[data-gallery]')

export function initVoucherUsageCarousel(): void {
  const voucherUSageCarousel = document.querySelector('#voucher-usage-carousel')

  if (!voucherUSageCarousel) return

  new Carousel(voucherUSageCarousel, {
    slidesPerPage: 4,
    center: false,
  })
}

interface SlideEvent extends Event {
  to: number
  from: number
}

export function initGalleries(): void {
  for (const $gallery of Array.from($galleries)) {
    initGalleryElement($gallery)
  }
}

export function initGalleryElement($gallery: HTMLElement): void {
  const touristVoucherGalleryItems = $gallery.getElementsByClassName('carousel-item')

  const itemsCountElement = $gallery.getElementsByClassName('gallery__count_element')[0]

  if (itemsCountElement) {
    itemsCountElement.innerHTML = touristVoucherGalleryItems.length.toString()
  }

  $gallery.addEventListener('slide.bs.carousel', function (e) {
    const activeSlideElement = $gallery.getElementsByClassName('gallery__active-index')[0]
    if (activeSlideElement) {
      const slideEvent = e as SlideEvent
      activeSlideElement.innerHTML = (slideEvent.to + 1).toString()
    }
  })
}
