import Modal from 'bootstrap/js/dist/modal'
import svgPanZoom from 'svg-pan-zoom'

const $resortMapModal = document.getElementById('resort-map-modal')
const $resortMapModalClose = document.getElementById('resort-map-modal-close')
const $resortMapModalShow = document.getElementById('resort-map-modal-show')

const $resortMap = document.getElementById('panned-map')

const $zoomInButton = document.getElementById('zoom-map-in')
const $zoomOutButton = document.getElementById('zoom-map-out')

let panZoom: SvgPanZoom.Instance

export const initResortMapModals = (): void => {
  const modal = new Modal($resortMapModal)

  $resortMapModalShow?.addEventListener('click', (event: MouseEvent) => {
    event.stopPropagation()
    modal.show()
    initMapPanZoom()
  })

  $resortMapModalClose?.addEventListener('click', (event: MouseEvent) => {
    event.stopPropagation()
    modal.hide()
  })

  $zoomInButton?.addEventListener('click', () => {
    panZoom?.zoomIn()
  })

  $zoomOutButton?.addEventListener('click', () => {
    panZoom?.zoomOut()
  })
}

export const initMapPanZoom = (): void => {
  const handleLoad = (event: Event) => {
    if ($resortMap) {
      $resortMap.style.height = `${(event.target as HTMLElement).scrollHeight}px`
    }

    function beforePan(this, oldPan: SvgPanZoom.Point, newPan: SvgPanZoom.Point): SvgPanZoom.PointModifier {
      const clientWidth = $resortMap?.scrollWidth ?? 0,
        clientHeight = $resortMap?.scrollHeight ?? 0,
        sizes = this.getSizes(),
        leftLimit = -((sizes.viewBox.x + sizes.viewBox.width) * sizes.realZoom) + clientWidth,
        rightLimit = sizes.width - clientWidth - sizes.viewBox.x * sizes.realZoom,
        topLimit = -((sizes.viewBox.y + sizes.viewBox.height) * sizes.realZoom) + clientHeight,
        bottomLimit = sizes.height - clientHeight - sizes.viewBox.y * sizes.realZoom

      return {
        x: Math.max(leftLimit, Math.min(rightLimit, newPan.x)),
        y: Math.max(topLimit, Math.min(bottomLimit, Math.min(newPan.y, 0))),
      }
    }

    panZoom = svgPanZoom('#panned-map', {
      beforePan,
      zoomScaleSensitivity: 0.4,
      mouseWheelZoomEnabled: false,
      dblClickZoomEnabled: false,
      preventMouseEventsDefault: false,
      minZoom: 1,
    })
  }

  $resortMap?.addEventListener('load', handleLoad)
}
