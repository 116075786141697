const $allResorts = document.querySelectorAll<HTMLDivElement>('[data-localization]')
const $resortsRadio = document.querySelectorAll<HTMLInputElement>('input[name="resortsFilter"]')
const $resortsCheckbox = document.querySelectorAll<HTMLInputElement>('[data-js-attraction-filter]')
const $clearButton = document.querySelector<HTMLButtonElement>('[data-js-clear-filter]')
const $showFiltersButton = document.querySelector<HTMLButtonElement>('[data-js-show-filters]')
const $filtersSection = document.querySelector<HTMLDivElement>('[data-js-filters]')
const $backdrop = document.querySelector<HTMLDivElement>('[data-js-backdrop]')
const $closeFilters = document.querySelectorAll<HTMLDivElement>('[data-js-close-filters]')

export type FilterLocalizationValue = 'allResorts' | 'mountains' | 'sea'
export type FilterAttractionValue = 'restaurant' | 'wellness'

const ATTRACTION = {
  restaurant: 'restauracja',
  wellness: ['basen wewnętrzny', 'salon masażu', 'sauna'],
}

function onFilterChange(): void {
  const localization = getCheckedLocalization()
  const attractions = getCheckedAttractions()

  hideElements($allResorts)

  if (attractions.length) {
    const elements = Array.from($allResorts).filter(
      el => IsResortFulfillAttractions(el, attractions) && isResortFulfilLocalization(el, localization),
    )
    showElements(elements)
  } else {
    const elements = Array.from($allResorts).filter(el => isResortFulfilLocalization(el, localization))
    showElements(elements)
  }
}

function clearFilters(): void {
  for (const checkbox of Array.from($resortsCheckbox)) {
    checkbox.checked = false
  }
  const $allRadio = Array.from($resortsRadio).find(el => (el.id as FilterLocalizationValue) === 'allResorts')
  if ($allRadio) {
    $allRadio.checked = true
  }
  showElements($allResorts)
}

function getCheckedAttractions(): FilterAttractionValue[] {
  return Array.from($resortsCheckbox)
    .filter(el => el.checked)
    .map(el => el.value) as FilterAttractionValue[]
}

function getCheckedLocalization(): FilterLocalizationValue {
  const $resortsFilter = document.querySelector<HTMLInputElement>('[name="resortsFilter"]:checked')
  return $resortsFilter ? ($resortsFilter.id as FilterLocalizationValue) : 'allResorts'
}

function IsResortFulfillAttractions(resort: HTMLDivElement, attractions: FilterAttractionValue[]): boolean {
  const resortAttractions =
    resort.dataset.attractions
      ?.split(',')
      .map(e => e.toLowerCase())
      .filter(Boolean) ?? []

  let hasRestaurant = true
  let hasSpa = true

  if (attractions.includes('restaurant')) {
    hasRestaurant = resortAttractions.includes(ATTRACTION['restaurant'])
  }

  if (attractions.includes('wellness')) {
    hasSpa = ATTRACTION['wellness'].some(el => resortAttractions.includes(el))
  }

  return hasRestaurant && hasSpa
}

function isResortFulfilLocalization(resort: HTMLDivElement, localization: FilterLocalizationValue): boolean {
  return localization === 'allResorts' ? true : resort.dataset.localization === localization
}

function showElements(elements: NodeListOf<HTMLDivElement> | HTMLDivElement[]): void {
  for (const el of Array.from(elements)) {
    el.hidden = false
  }
}

function hideElements(elements: NodeListOf<HTMLDivElement> | HTMLDivElement[]): void {
  for (const el of Array.from(elements)) {
    el.hidden = true
  }
}

function toggleFilters(): void {
  if ($filtersSection && $backdrop) {
    $filtersSection.classList.toggle('is-open')
    $backdrop.classList.toggle('is-open')
  }
}

function closeFilters(): void {
  if ($filtersSection && $backdrop && $backdrop.classList.contains('is-open')) {
    $filtersSection.classList.remove('is-open')
    $backdrop.classList.remove('is-open')
  }
}

export function initFilters(): void {
  $resortsRadio.forEach(input => {
    input.addEventListener('change', onFilterChange)
  })

  $resortsCheckbox.forEach(input => {
    input.addEventListener('change', onFilterChange)
  })

  if ($clearButton) {
    $clearButton.addEventListener('click', clearFilters)
  }

  if ($showFiltersButton) {
    $showFiltersButton.addEventListener('click', toggleFilters)
  }

  $closeFilters.forEach(el => {
    el.addEventListener('click', closeFilters)
  })
}
