import * as React from 'react'
import { Dates } from 'dates-selection-range'
import { format, addDays } from 'date-fns'
import { ReservationMobileCalendar } from './reservation-calendar/reservation-mobile-calendar'
import { ReservationDesktopCalendar } from './reservation-calendar/reservation-desktop-calendar'
import { AppData } from './models'
import { WINDOWS_EVENT } from './app-data'

const ReservationUrl = 'https://rezerwuj.holidaypark.pl'

interface AppDataLoadEventTarget {
  appData?: AppData
}

interface DataLayer {
  push: ({ event, userID }) => void
}

declare global {
  interface Window {
    appData: AppData | null
    dataLayer?: DataLayer
  }
}

export const initialDates = {
  date_from: format(new Date(), 'yyyy-MM-dd'),
  date_to: format(addDays(new Date(), 2), 'yyyy-MM-dd'),
}

const App = (): JSX.Element => {
  const [isVisible, setIsVisible] = React.useState(false)
  const [dates, setDates] = React.useState<Dates>(initialDates)
  const [resortName, setResortName] = React.useState('')

  const [appData, setAppData] = React.useState(window.appData)

  React.useEffect(() => setAppData(window.appData), [window.appData])

  const handleShowDatePicker = () => {
    setIsVisible(true)
  }

  const handleHideDatePicker = () => {
    setIsVisible(false)
  }

  const handleRedirectToReservationProcess = () => {
    document.location.href = `${appData?.reservation_url || ReservationUrl}?date_from=${dates?.date_from}&date_to=${
      dates?.date_to
    }`
  }

  React.useEffect(() => {
    const resortCalendar = document.querySelector<HTMLDivElement>('[data-calendar-resort]')
    if (resortCalendar && resortCalendar.dataset.calendarResort) {
      setResortName(resortCalendar.dataset.calendarResort)
    }
  }, [])

  React.useEffect(() => {
    window.addEventListener(WINDOWS_EVENT.APP_DATA_LOADED, (event: Event) => {
      setAppData((event.target as AppDataLoadEventTarget).appData ?? null)
    })
  }, [])

  return (
    <>
      <div
        className={`main-page__reservation-overlay ${isVisible ? 'is-visible' : ''}`}
        onClick={handleHideDatePicker}
      />
      <div
        className={`main-page__reservation-wrapper ${isVisible ? '' : 'is-hidden'} ${
          resortName ? '' : 'no-localization'
        }`}
      >
        <ReservationDesktopCalendar
          seasons={appData?.seasons || []}
          setDates={setDates}
          onMoveToReservationProcess={handleRedirectToReservationProcess}
          onShowDatePicker={handleShowDatePicker}
          onHideDatePicker={handleHideDatePicker}
          resortName={resortName}
        />
        <ReservationMobileCalendar
          seasons={appData?.seasons || []}
          setDates={setDates}
          onMoveToReservationProcess={handleRedirectToReservationProcess}
          onShowDatePicker={handleShowDatePicker}
          isOverlayVisible={isVisible}
        />
      </div>
    </>
  )
}

export default App
