import { GTMEvents, SessionStorageKeys } from './models'

export const attachVirtualWalkButtonEvent = (): void => {
  const $virtualWalks = document.querySelectorAll('.virtual-walk')

  $virtualWalks.forEach($virtualWalk => {
    $virtualWalk.addEventListener('click', () => {
      window.dataLayer?.push({
        event: GTMEvents.VirtualWalk,
        userID: window.sessionStorage.getItem(SessionStorageKeys.userID),
      })
    })
  })
}
