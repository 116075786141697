import { DeviceSize } from './responsive-slides-carousel'

const $groupPriceTables = document.querySelectorAll<HTMLButtonElement>('[data-pricelist-group]')
const $groupSelects = document.querySelectorAll<HTMLButtonElement>('[data-pricelist-group-select]')
const $buttons = document.querySelectorAll<HTMLButtonElement>('[data-pricelist-season-select]')
const $priceLists = document.querySelectorAll<HTMLButtonElement>('[data-pricelist-season]')
const $priceListHeaders = document.querySelectorAll<HTMLButtonElement>('[data-pricelist-header-season]')

export const initPriceList = (): void => {
  for (const $button of Array.from($buttons)) {
    initSeasonButton($button)
  }

  for (const $groupSelect of Array.from($groupSelects)) {
    initGroupSelectionButton($groupSelect)
  }
}

const initSeasonButton = ($button: HTMLButtonElement) => {
  const season = $button.getAttribute('data-pricelist-season-select')
  $button.addEventListener('click', (event: Event) => {
    for (const $but of Array.from($buttons)) {
      $but.classList.remove('active')
    }

    $button.classList.add('active')

    for (const $priceList of Array.from($priceLists)) {
      if ($priceList.getAttribute('data-pricelist-season') === season) {
        $priceList.classList.remove('d-none')
      } else {
        $priceList.classList.add('d-none')
      }
    }

    for (const $priceListHeader of Array.from($priceListHeaders)) {
      if ($priceListHeader.getAttribute('data-pricelist-header-season') === season) {
        $priceListHeader.classList.remove('d-none')
      } else {
        $priceListHeader.classList.add('d-none')
      }
    }

    event.stopPropagation()
  })
}

const initGroupSelectionButton = ($button: HTMLButtonElement) => {
  const isDesktop = window.matchMedia(DeviceSize.desktop).matches

  const responsivenessElements = Array.from($groupPriceTables).filter(el => {
    if (el.classList.contains('is-mobile') && !isDesktop) {
      return true
    }

    return el.classList.contains('is-desktop') && isDesktop
  })

  for (const $table of responsivenessElements.values()) {
    const group = $table.getAttribute('data-pricelist-group')

    if (group && ['group_2', 'group_3'].includes(group)) {
      $table.style.display = 'none'
    }
  }

  $button.addEventListener('click', (event: Event) => {
    const group = $button.getAttribute('data-pricelist-group-select')

    for (const $but of Array.from($groupSelects)) {
      $but.classList.remove('is-active')
    }

    for (const $groupPriceTable of responsivenessElements) {
      const groupTable = $groupPriceTable.getAttribute('data-pricelist-group')
      $groupPriceTable.style.display = group === groupTable ? 'table' : 'none'
    }

    $button.classList.add('is-active')

    event.stopPropagation()
  })
}
