import { GTMEvents, SessionStorageKeys } from './models'
import { DeviceSize } from './responsive-slides-carousel'
import Tick from '@pqina/flip'
import Hls from 'hls.js'

interface Tick {
  DOM: { create: (...args) => Tick }
  destroy: () => void
}

const $openItem = document.getElementById('weather-box-trigger')
const $content = document.getElementById('weather-box')
const $contentMobile = document.getElementById('weather-box-mobile')
const $resorts = document.querySelectorAll<HTMLLIElement>('[data-resort]')
const $videos = document.querySelectorAll<HTMLButtonElement>('[data-weather-video]')
const $closeItems = document.querySelectorAll('[data-weather-box-close]')
const $videoIframes = document.querySelectorAll<HTMLVideoElement>('[data-weather-iframe]')
const $cameraPlaceholders = document.querySelectorAll<HTMLDivElement>('[data-weather-placeholder]')

const HlsViewers = new Map<string, Hls>()

export const initWeatherBox = (): void => {
  initToggle()
  initResorts()
}

const initToggle = () => {
  const isDesktop = window.matchMedia(DeviceSize.desktop).matches

  if (!$openItem || !$content || !$contentMobile) return

  $openItem.addEventListener('click', (event: Event) => {
    window.dataLayer?.push({
      event: GTMEvents.Live_Weather_Open,
      userID: window.sessionStorage.getItem(SessionStorageKeys.userID),
    })

    $content.classList.add('is-visible')
    $contentMobile.classList.add('is-visible')
    $openItem.classList.remove('is-visible')
    document.body.classList.add('block-scroll')

    Array.from($resorts).forEach($resort => {
      if (!isDesktop) calendarTick().create($resort.getAttribute('data-resort'))

      if (!$resort.classList.contains('is-active')) return

      for (const $iframe of Array.from($videoIframes)) {
        const desktopDevice = $iframe.getAttribute('data-weather-iframe-device') === 'desktop'
        const resortName = $resort.getAttribute('data-resort')
        if (
          resortName &&
          ((resortName === $iframe.getAttribute('data-weather-iframe') && isDesktop && desktopDevice) || !isDesktop)
        ) {
          createHlsPlayer(resortName, $iframe)
        }
      }
    })

    event.stopPropagation()
  })

  if (!Array.from($closeItems).length) return

  for (const $closeItem of Array.from($closeItems)) {
    $closeItem.addEventListener('click', (event: Event) => {
      $content.classList.remove('is-visible')
      $contentMobile.classList.remove('is-visible')
      $openItem.classList.add('is-visible')
      document.body.classList.remove('block-scroll')

      if (!isDesktop) calendarTick().destroyAll()

      event.stopPropagation()

      setTimeout(() => {
        for (const $iframe of Array.from($videoIframes)) {
          $iframe.setAttribute('src', '')
        }

        HlsViewers.forEach(viewer => viewer.destroy())
        HlsViewers.clear()
      }, 500)
    })
  }
}

const initResorts = () => {
  const isDesktop = window.matchMedia(DeviceSize.desktop).matches

  for (const [index, $resort] of Array.from($resorts).entries()) {
    if (index === 0) {
      $resort.classList.add('is-active')
      calendarTick().create($resort.getAttribute('data-resort'))
    }

    $resort.addEventListener('click', (event: Event) => {
      for (const $restResorts of Array.from($resorts)) {
        $restResorts.classList.remove('is-active')
        calendarTick().destroyAll()
      }

      const resortName = $resort.getAttribute('data-resort')

      $resort.classList.add('is-active')
      calendarTick().create(resortName)

      window.dataLayer?.push({
        event: GTMEvents.Live_Weather_Location,
        userID: window.sessionStorage.getItem(SessionStorageKeys.userID),
      })

      for (const $video of Array.from($videos)) {
        if ($video.getAttribute('data-weather-video') === resortName) {
          $video.classList.remove('d-none')
        } else {
          $video.classList.add('d-none')
        }

        for (const $frame of Array.from($videoIframes)) {
          const resortIframe = $frame.getAttribute('data-weather-iframe')
          const desktopDevice = $frame.getAttribute('data-weather-iframe-device') === 'desktop'
          if (resortName && ((resortName === resortIframe && desktopDevice && isDesktop) || !isDesktop)) {
            createHlsPlayer(resortName, $frame)
          }
        }
      }

      event.stopPropagation()
    })
  }
}

const calendarTick = () => {
  const ticksElement: { resort: string; tick: Tick }[] = []

  const create = resort => {
    const $placeholders = Array.from($cameraPlaceholders).filter((cameraForResort: HTMLDivElement) => {
      return resort === cameraForResort.getAttribute('data-weather-placeholder')
    })

    $placeholders.forEach($cameraPlaceholder => {
      const cameraActiveFrom = $cameraPlaceholder.getAttribute('data-weather-camera-active-from')
      if (cameraActiveFrom === 'None' || !cameraActiveFrom) return

      const handleTickInit = tick => {
        const [day, month, year] = cameraActiveFrom.split('.')

        const tickCounter = Tick.count.down(`${year}-${month}-${day}T00:00:00`, { format: ['d', 'h', 'm', 's'] })

        tickCounter.onupdate = value => {
          tick.value = value
        }
      }

      ticksElement.push({
        resort,
        tick: Tick.DOM.create($cameraPlaceholder, {
          didInit: handleTickInit,
        }),
      })

      const localization = {
        DAY_PLURAL: 'd',
        DAY_SINGULAR: 'd',
        MINUTE_PLURAL: 'm',
        MINUTE_SINGULAR: 'm',
        HOUR_PLURAL: 'h',
        HOUR_SINGULAR: 'h',
        SECOND_PLURAL: 's',
        SECOND_SINGULAR: 's',
      }

      Object.entries(localization).forEach(([key, value]) => Tick.options.setConstant(key, value))
    })
  }

  const destroy = resort => {
    const element = ticksElement.find(tick => tick.resort === resort)
    if (element) element.tick.destroy()
  }

  const destroyAll = () => {
    ticksElement.forEach(element => element.tick.destroy)
  }

  return {
    create,
    destroy,
    destroyAll,
  }
}

const createHlsPlayer = (resort: string, $iframe: HTMLVideoElement) => {
  const isDesktop = window.matchMedia(DeviceSize.desktop).matches

  if (isDesktop) {
    HlsViewers.forEach(viewer => viewer.destroy())
    HlsViewers.clear()
  }

  $iframe.disablePictureInPicture = true

  const videoSrc = $iframe.getAttribute('data-weather-iframe-src') ?? ''

  if (Hls.isSupported()) {
    const resortViewer = new Hls()
    resortViewer.loadSource(videoSrc)
    resortViewer.attachMedia($iframe)
    HlsViewers.set(resort, resortViewer)
  } else if ($iframe.canPlayType('application/vnd.apple.mpegurl')) {
    $iframe.src = videoSrc
  }
}
