import { DeviceSize } from './responsive-slides-carousel'

const $link = document.getElementById('ecommerce-banner-link')

export const initEcommerceBanner = (): void => {
  const isDesktop = window.matchMedia(DeviceSize.desktop).matches
  if (!$link) return

  const handleLinkClick = () => {
    $link.style.right = '-300px'

    $link.removeEventListener('click', handleLinkClick)
  }

  if (!isDesktop) {
    $link.addEventListener('click', handleLinkClick)
  }
}
