const $rangeWrappers = document.querySelectorAll<HTMLDivElement>('[data-range-wrapper]')
const $costSummary = document.querySelectorAll<HTMLDivElement>('[data-range-summary-cost]')
const $installmentsSummary = document.querySelectorAll<HTMLDivElement>('[data-range-summary-installments]')
const $vacationCost = document.querySelector<HTMLInputElement>('#vacation-cost')
const $installments = document.querySelector<HTMLInputElement>('#installments')

function calculateTrackerPosition($range: HTMLInputElement): number {
  const sliderValue = parseFloat($range.value)
  const min = parseFloat($range.min)
  const max = parseFloat($range.max)
  const value = sliderValue < min ? min : sliderValue > max ? max : sliderValue
  let percentage = (value - min) / (min - max)
  percentage += 0.5
  percentage *= 87 // 100 percents reduced by input padding & thumb width
  percentage *= -1
  return Math.round(percentage * 10) / 10
}

function handleTipPosition($rangeHelper: HTMLSpanElement, trackerPosition: number): void {
  $rangeHelper.style.left = `${trackerPosition * 2}%`
}

function handleTipContent($rangeHelper: HTMLSpanElement, $range: HTMLInputElement) {
  $rangeHelper.innerHTML = `${$range.value} ${$rangeHelper.dataset.rangeHelper}`
}

function handleSummary(): void {
  if ($costSummary.length && $installmentsSummary.length && $installments && $vacationCost) {
    for (const el of Array.from($costSummary)) {
      el.innerHTML = `${$vacationCost.value} zł`
    }

    for (const el of Array.from($installmentsSummary)) {
      el.innerHTML = `${$installments.value} ${el.dataset.rangeSummaryInstallments}`
    }
  }
}

function onSliderChange($rangeHelper: HTMLSpanElement, $range: HTMLInputElement): void {
  handleTipPosition($rangeHelper, calculateTrackerPosition($range))
  handleTipContent($rangeHelper, $range)
  handleSummary()
}

function onUpBtnClick($rangeHelper: HTMLSpanElement, $range: HTMLInputElement): void {
  const value = parseFloat($range.value)
  const max = parseFloat($range.max)
  const step = parseFloat($range.step)

  if (value < max) {
    $range.value = (value + step).toString()
    onSliderChange($rangeHelper, $range)
  }
}

function onDownBtnClick($rangeHelper: HTMLSpanElement, $range: HTMLInputElement): void {
  const value = parseFloat($range.value)
  const min = parseFloat($range.min)
  const step = parseFloat($range.step)

  if (value > min) {
    $range.value = (value - step).toString()
    onSliderChange($rangeHelper, $range)
  }
}

export default function initRange(): void {
  for (const $rangeWrapper of Array.from($rangeWrappers)) {
    const $range = $rangeWrapper.querySelector<HTMLInputElement>('[data-range-input]')
    const $rangeHelper = $rangeWrapper.querySelector<HTMLSpanElement>('[data-range-helper]')
    const $upBtn = $rangeWrapper.querySelector<HTMLSpanElement>('[data-range-up-btn]')
    const $downBtn = $rangeWrapper.querySelector<HTMLSpanElement>('[data-range-down-btn]')

    if (!$range || !$rangeHelper || !$upBtn || !$downBtn) {
      return
    }

    onSliderChange($rangeHelper, $range)
    $range.addEventListener('change', () => onSliderChange($rangeHelper, $range))
    $range.addEventListener('input', () => onSliderChange($rangeHelper, $range))
    $upBtn.addEventListener('click', () => onUpBtnClick($rangeHelper, $range))
    $downBtn.addEventListener('click', () => onDownBtnClick($rangeHelper, $range))
  }
}
