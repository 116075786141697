export const attachHamburgerEvent = (): void => {
  const $hamburger = document.querySelector('[data-js-hamburger]')
  const $linksWrapper = document.querySelector('[data-js-navbar]')
  const $navbarWrapper = document.querySelector('[data-js-navbar-wrapper]')

  setNavbarClass()

  if ($hamburger) {
    $hamburger.addEventListener('click', () => {
      $hamburger.classList.toggle('active')
      document.body.classList.toggle('block-scroll')

      if ($linksWrapper) {
        $linksWrapper.classList.toggle('active')
      }
    })
  }

  document.addEventListener('scroll', setNavbarClass)

  function setNavbarClass(): void {
    if (window.location.href === `${window.location.origin}/` && $navbarWrapper) {
      if (getScrollTop()) {
        $navbarWrapper.classList.remove('is-transparent')
      } else {
        $navbarWrapper.classList.add('is-transparent')
      }
    }
  }
}

function getScrollTop(): number {
  return document.documentElement.scrollTop || document.body.scrollTop
}
