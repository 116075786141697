require('ol/ol.css')

import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import OSM from 'ol/source/OSM'
import Vector from 'ol/source/Vector'
import Map from 'ol/Map'
import View from 'ol/View'
import TileLayer from 'ol/layer/Tile'
import VectorLayer from 'ol/layer/Vector'
import Icon from 'ol/style/Icon'
import Style from 'ol/style/Style'
import { fromLonLat } from 'ol/proj'

const $maps = document.querySelectorAll<HTMLDivElement>('[data-map]')

export const initMaps = (): void => {
  for (const $map of Array.from($maps)) {
    initMap($map)
  }
}

const initMap = ($map: HTMLDivElement) => {
  $map.innerHTML = ''

  const lon = $map.getAttribute('data-map-lon') || ''
  const lat = $map.getAttribute('data-map-lat') || ''

  const iconFeature = new Feature({
    geometry: new Point(fromLonLat([parseFloat(lon), parseFloat(lat)])),
    name: $map.getAttribute('data-map-name'),
  })

  new Map({
    target: $map,
    layers: [
      new TileLayer({
        source: new OSM(),
      }),
      new VectorLayer({
        source: new Vector({
          features: [iconFeature],
        }),
        style: new Style({
          image: new Icon({
            anchor: [0.5, 46],
            anchorXUnits: 'fraction',
            anchorYUnits: 'pixels',
            src: '/static/website/images/icon.png',
          }),
        }),
      }),
    ],
    view: new View({
      center: fromLonLat([parseFloat(lon), parseFloat(lat)]),
      zoom: 9,
    }),
  })
}

document.addEventListener('shown.bs.modal', function () {
  initMaps()
})
