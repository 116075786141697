import Toast from 'bootstrap/js/dist/toast'
import { GTMEvents, SessionStorageKeys } from './models'

export const initNewsletter = (): void => {
  const $newsletterForms = Array.from(document.querySelectorAll('.newsletter-form')) as HTMLFormElement[]
  setErrorMessage(null)
  if (!$newsletterForms.length) return

  $newsletterForms.forEach($newsletter => {
    $newsletter?.addEventListener('submit', handleFormSubmit)
  })
}

const handleFormSubmit = (event: SubmitEvent) => {
  event.preventDefault()

  const formElement = event.target as HTMLFormElement
  signUpToNewsletter(formElement)
}

const signUpToNewsletter = async (formElement: HTMLFormElement) => {
  const formData = new FormData(formElement)

  const url = window.appData?.newsletter_sign_up_url
  if (!url) return

  setErrorMessage(null)

  const response = await fetch(url, { method: 'POST', body: formData })
  const data = await response.json()

  if (response.status === 400) {
    setErrorMessage(data.email)
  }

  if (response.status === 201) {
    showConfirmationToast()
    formElement.reset()

    window.dataLayer?.push({
      event: GTMEvents.Newsletter,
      userID: window.sessionStorage.getItem(SessionStorageKeys.userID),
    })
  }
}

const setErrorMessage = (error: string | null) => {
  const $errorElements = Array.from(document.querySelectorAll('.newsletter-form-error')) as HTMLElement[]
  if (!$errorElements.length) return

  $errorElements.forEach($errorElement => {
    $errorElement.innerText = error ?? ''
    $errorElement.style.display = error ? 'block' : 'none'
  })
}

const showConfirmationToast = () => {
  const $toasts = Array.from(document.querySelectorAll('.toast'))

  const $toastList = $toasts.map(function ($toast) {
    return new Toast($toast, { delay: 7000 })
  })

  $toastList.forEach(e => e.show())
}
