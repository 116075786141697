import * as React from 'react'
import { DatesSelectionRange, Dates } from 'dates-selection-range'
import { initialDates } from '../app'

interface DateSelectionParams {
  onRangeFocusChange: (range: number[]) => void
  setRange: (value: React.SetStateAction<boolean>) => void
  isPickingEndDate: boolean
  isRange: boolean
  toggleModal: () => void
}

const stayDeclination = (days: number): string => {
  if (days === 1) {
    return 'doba'
  } else if (days > 1 && days < 5) {
    return 'doby'
  } else {
    return 'dób'
  }
}

const useDateSelection = (): DateSelectionParams => {
  const [isPickingEndDate, setIsPickingEndDate] = React.useState<boolean>(false)
  const [isRange, setRange] = React.useState(false)

  const toggleModal = React.useCallback(() => {
    setRange(state => !state)
  }, [])

  const onRangeFocusChange = ([, isPickingEndDate]: number[]) => {
    setIsPickingEndDate(!!isPickingEndDate)
  }

  return {
    onRangeFocusChange,
    setRange,
    isPickingEndDate,
    isRange,
    toggleModal,
  }
}

interface Props {
  onVisibilityChange: () => void
  setDates: (dates: Dates) => void
  months: number
  seasons: number[]
}

export const ReservationDateSelection = ({ onVisibilityChange, setDates, months, seasons }: Props) => {
  const dateSelection = useDateSelection()

  return (
    <DatesSelectionRange
      dateSelection={dateSelection}
      months={months}
      direction="horizontal"
      toggleDateChangeVisibility={onVisibilityChange}
      seasons={seasons}
      maxReservationDays={21}
      onDatesUpdated={setDates}
      dateFrom={initialDates.date_from}
      dateTo={initialDates.date_to}
      getStayDeclination={stayDeclination}
    />
  )
}
