import * as React from 'react'
import { ReservationDateSelection } from './reservation-date-selection'
import { Dates } from 'dates-selection-range'

interface Props {
  setDates: (dates: Dates) => void
  onShowDatePicker: () => void
  onMoveToReservationProcess: () => void
  isOverlayVisible: boolean
  seasons: number[]
}

export const ReservationMobileCalendar = ({
  setDates,
  onShowDatePicker,
  onMoveToReservationProcess,
  isOverlayVisible,
  seasons,
}: Props) => {
  const [isMobileDatesPickerVisible, setIsMobileDatesPickerVisible] = React.useState(false)

  const handleShowDatePicker = () => {
    setIsMobileDatesPickerVisible(true)
    onShowDatePicker()
  }

  React.useEffect(() => {
    if (!isOverlayVisible && isMobileDatesPickerVisible) {
      setIsMobileDatesPickerVisible(false)
    }
  }, [isOverlayVisible])

  return (
    <div className="d-block d-lg-none main-page__reservation-box-mobile">
      {isMobileDatesPickerVisible ? (
        <ReservationDateSelection
          seasons={seasons}
          months={1}
          setDates={setDates}
          onVisibilityChange={onMoveToReservationProcess}
        />
      ) : (
        <div className="p-3 bg-white main-page__reservation-button-mobile">
          <button className="btn btn-red main-page__button py-3" onClick={handleShowDatePicker}>
            Rezerwuj
          </button>
        </div>
      )}
    </div>
  )
}
