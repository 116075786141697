export interface AppData {
  client_url: string
  reservation_url: string
  newsletter_sign_up_url: string
  seasons: number[]
}

export enum SessionStorageKeys {
  userID = 'userID',
}

export enum GTMEvents {
  Live_Weather_Open = 'live_weather_open',
  Live_Weather_Location = 'live_weather_location',
  Popup_Click = 'popup_click',
  Newsletter = 'newsletter',
  VirtualWalk = 'virtual_walk',
}
