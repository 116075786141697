const APP_DATA_URL = '/api/cms/app-data/'

export enum WINDOWS_EVENT {
  APP_DATA_LOADED = 'APP_DATA_LOADED',
}

export const fetchAppData = (): void => {
  const fetchData = async (): Promise<void | null> => {
    try {
      const response = await fetch(APP_DATA_URL)
      const appData = await response.json()
      window.appData = appData
      window.dispatchEvent(new CustomEvent(WINDOWS_EVENT.APP_DATA_LOADED, appData))
    } catch (e) {
      console.log(e)
      return null
    }
  }

  fetchData()
}
