const $expandButtons = document.querySelectorAll<HTMLButtonElement>('[data-js-expand-more]')

function onButtonClick(event): void {
  const id = event.target.dataset.jsExpandMore
  const $dots = document.querySelector<HTMLSpanElement>(`[data-js-dots="${id}"]`)
  const $moreText = document.querySelector<HTMLSpanElement>(`[data-js-more-text="${id}"]`)

  if ($dots) {
    $dots.hidden = true
  }

  if ($moreText) {
    $moreText.hidden = false
  }

  event.target.hidden = true

  event.target.removeEventListener('click', onButtonClick)
}

export function initExpandText(): void {
  for (const btn of Array.from($expandButtons)) {
    btn.addEventListener('click', onButtonClick)
  }
}
